<template>
  <div>
    <div class="c-app" v-if="isLoggedIn">
      <CWrapper>
        <TheHeader/>
        <div class="container">
          <div class="mt-2 rounded d-flex" style="background-color: #FFFFFF">
            <marquee onmouseover="this.stop()" onmouseout="this.start()" class="p-1">ยินดีต้อนรับเข้าสู่ BMLOT88</marquee>
          </div>
          <MainMenu class="py-2" />
        </div>
        <div class="c-body container">
          <!-- <main class="c-main p-2">
            <CContainer class="p-2">
              <transition name="fade" mode="out-in">
                <router-view :key="$route.path"></router-view>
              </transition>
            </CContainer>
          </main> -->
          <router-view :key="$route.path"></router-view>
        </div>
        <TheFooter/>
      </CWrapper>
    </div>
    <div v-else style="background-color: #ffb703; color: #FFFFFF;" class="check-login">
      <loading
        :active="true"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#ffb703"
        :height="80"
        :width="80"
        color="#FFFFFF"
        loader="dots"
      />
    </div>
    <AlertMessage />
  </div>
</template>

<script>
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import MainMenu from '@/containers/MainMenu'

import AuthService from '@/services/authService'
import cAlert from '@/helpers/alert'
import Auth from '@/helpers/auth'

import AlertMessage from '@/views/components/AlertMessage'

export default {
  name: 'TheContainer',
  components: {
    TheHeader,
    MainMenu,
    TheFooter,
    AlertMessage
  },
  data() {
    return {
      isLoggedIn: false
    }
  },
  beforeCreate() {
    AuthService.getProfile()
    .then((response) => {
      if(response.success) {
        this.isLoggedIn = true
        this.$store.commit('updateProfile', response.data)
        this.$store.dispatch('reloadMarkets')
        this.$store.dispatch('reloadMarketGroups')
      }else{
        throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
      }
    })
    .catch((e)=>{
      cAlert({
        ...e,
        title: 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
        text: e?.message,
        icon: 'error',
        confirmButtonText: 'OK'
      }, ()=>{
        Auth.logout()
        this.$router.push({name: 'Login'})
      })
    })
  },
  mounted() {
    // const viewport = document.querySelector("meta[name=viewport]")
    // viewport.setAttribute('content', 'width=1200,initial-scale=1')
  }
}
</script>

<style scoped>
.c-main {
  background-color: #FFFFFF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.check-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
