<template>
  <CHeader class="d-flex">
    <div class="container">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <div class="app-logo">
            <router-link to="/">
              <img src="@/assets/bmlot88-2.png">
            </router-link>
          </div>
        </div>
        <CHeaderNav class="ml-auto">
          <TheHeaderTime class="mr-4" />
          <TheHeaderBalance class="mr-2" />
          <TheHeaderDropdownAccnt/>
        </CHeaderNav>
      </div>
    </div>
  </CHeader>
</template>

<script>
import TheHeaderTime from './TheHeaderTime'
import TheHeaderBalance from './TheHeaderBalance'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderTime,
    TheHeaderBalance,
    TheHeaderDropdownAccnt
  },
  methods: {
    logout() {
      if(this.$store.state.isProcessTicket) {
        Swal.fire({
          text: 'กรุณาอย่าเปลี่ยนหน้าขณะกำลังส่งโพย',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }else{
        Auth.logout()
      }
    }
  }
}
</script>
<style lang="scss">
.c-header {
  background-color: #E5881E;

  .app-logo {
    img {
      height: 55px;
    }
  }

  .c-header-nav-link {
    color: #FFFFFF !important;
  }
}
</style>
